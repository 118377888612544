<template>
    <div class="news_photo dirr">
        <div class="news_con d-sm-flex">
            <div class="most" v-if="mostNews_skeleton"> 
                <v-layout row>
                    <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="325" style="top:10px" ></v-skeleton-loader>
                </v-layout>
                
                <hr>
                <v-layout row>
                    <v-flex lg12 style="padding:0 "> <v-skeleton-loader width="100%" height="200" type="image"></v-skeleton-loader> </v-flex>
                </v-layout>
                <div class="button-section">
                    <div class="button-container d-flex">
                    <v-skeleton-loader style="margin: 20px;" type="button"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" type="button"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" type="button"></v-skeleton-loader>
                    </div>
                </div>
                <v-layout>
                    <v-flex sm12 md6 lg4 style="padding:0 10px;"><v-skeleton-loader  height="180" type="image"></v-skeleton-loader></v-flex>
                    <v-flex sm12 md6 lg4 style="padding:0 10px;"><v-skeleton-loader  height="180" type="image"></v-skeleton-loader></v-flex>
                    <v-flex sm12 md6 lg4 style="padding:0 10px;"><v-skeleton-loader  height="180" type="image"></v-skeleton-loader></v-flex>
                    
                </v-layout>


            </div>
            <template v-else>

            
                <div class="title mx-2"  style=" display:flex">
                    <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                    <p class="tl" style="font-size: 24px; padding: 0 15px;">{{ $t("Picture_Reports") }}</p>
                </div>
                <v-container fluid class="breadcrumb-container" :style="{ backgroundImage: `url(${isMobile && mob_img ? mob_img : main_img})` }">
                    <v-row>
                    <v-col cols="12">
                        <v-breadcrumbs>
                        
                        </v-breadcrumbs>
                    </v-col>
                    </v-row>
                </v-container>
             
                <div class="my-1"></div>

                <!-- <div class="button-section w-100">
                    <div class="button-container d-flex scroll-horizontal">
                        <v-btn class="custom-button mx-2" @click="info_all()">
                            <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" class="button-icon" />
                            <span :style="$vuetify.theme.dark ? 'color:#FFF' : ''"> الكل </span>
                        </v-btn>

                        <template v-for="cat in cats">
                            <v-btn class="custom-button mx-2" @click="info_cat(cat.req)">
                                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" class="button-icon" />
                                <span :style="$vuetify.theme.dark ? 'color:#FFF' : ''">{{ cat.title }}</span>
                            </v-btn>
                        </template>
                        
                    </div>
                </div> -->

                <div class="mt-5"></div>
                <hr>

                <v-container>
                    <v-row class="inf"> 
                        <v-col v-for="(card, cardIndex) in cards" :key="cardIndex" cols="12" md="6" lg="4" style="margin-bottom: 50px;">
                            <transition name="fade">
                                <v-carousel :cycle="activeIndex === cardIndex ? true : false" interval="3000" :show-arrows="false">
                                    <v-carousel-item v-for="(image, i) in [card.image, ...card.images]" :key="i">
                                        <router-link :to="'/new/infograph_inner/'+ card.id + '?&lang=' +$i18n.locale" class="item">
                                            <img :src="image.includes('http') ? image : 'https://alkafeel.net/news/images/main/370/' + image" class="image-hover-scale full-height-img">
                                        </router-link>
                                    </v-carousel-item>
                                </v-carousel>
                            </transition>
                        </v-col>

                        <div class="mx-auto">
                            <v-btn width="150" height="40" class="ma-2 tl" style="align-self: center !important;" color="rgba(177, 189, 82, 1)">
                                <p class="getMorenews alistSub tl"> {{ $t('view_more') }} </p>
                            </v-btn>
                        </div>
                    </v-row>


                </v-container>
            </template>


            


        </div>
      
    </div>
    </template> 
    
    <script>
    import searchVue from './search.vue';
    // import Swiper from 'swiper';
    // import 'swiper/css/swiper.css';
    export default {
        data() {
            return {
                activeIndex: 0,
                mostNews : [{"id":"","title":"","image":"", "images":[]}],
                News : [{"id":"","title":"","image":""}],
                picNews : [],
                mostNews_skeleton : true,
                NewsPage : 1,
                getMorenews : true,
                loader: null,
                loading: false,
                offsetTop: 0,
                axios_load: false,
                cats: [],
                cat_id: 0,
                visibleCards: [],
                main_img: '',
                mob_img: '',
                cardTime: 3000,
                attrs: {
                    class: 'mb-6',
                    boilerplate: false,
                    elevation: 2,
                },
                isMobile: false,
                cards: [ 
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
                { image: '', images: ["https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg", "https://alkafeel.net/news/images/main/ebd7be965db5b361da3c3e13aa0d6482.jpg"] },
            ],
            }
        },
        watch: {
            loader () {
                const l = this.loader
                this[l] = !this[l]
                setTimeout(() => (this[l] = false), 2000)
                this.loader = null
            },
            offsetTop (val) {
                this.callbackFunc()
            }
        },
        components: {
            'news-search': searchVue
        },
        directives: {},
        computed: {},
        mounted() {
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);
            document.title =this.$t("Picture_Reports"); 
        }, 
        beforeDestroy() {
            window.removeEventListener('resize', this.checkIfMobile);
        },
        created () {
            
            this.loadCats();
            // this.getmostNews();
            this.grtpicNews();
            this.infograph_img();
            window.addEventListener('scroll', this.onScroll);
        },
        methods:{
            nextSlide() {
                if ( this.cards[this.activeIndex+1] !== undefined && Array.isArray(this.cards[this.activeIndex+1].images) ) {
                    this.activeIndex++;
                    this.cardTime = (this.cards[this.activeIndex].images.length * 3000) + 2000;
                } else {
                    this.activeIndex = 0;
                }
                setTimeout(this.startAutoplay, 1000);
            },
            startAutoplay() {
                if (this.activeIndex === 0) {
                    this.cardTime = (this.cards[0].images.length * 3000) + 2000;
                }
                setTimeout(this.nextSlide, this.cardTime);
            },
            checkIfMobile() {
                this.isMobile = window.innerWidth <= 468;
            },
            info_all () {
                this.cat_id = 0;
                this.cards = [{"id":"","title":"","image":"", "images":[]}];
                this.NewsPage = 1;
                this.grtpicNews();
            },
            info_cat (id) {
                this.cat_id = id;
                this.cards = [{"id":"","title":"","image":"", "images":[]}];
                this.NewsPage = 1;
                this.grtpicNews();
            },
            loadCats() {
                this.$axios.get("articles/GetInfographCats/6")
                .then(data => {
                    this.cats = data.data;
                })
            },
            onScroll (e) {
                setTimeout(() => {
                    if (!this.axios_load) {this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;}
                }, 700);
            },
            isElementInViewport(el) {
                var rect = el.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },
            callbackFunc() {
                let el = document.querySelector('.getMorenews');
                if (this.isElementInViewport(el)) {this.grtpicNews();}
            },
            getmostNews () {
                this.$axios.get("articles/Getphotonews/5?page=1", {
                })
                .then(res => {
                    // this.NewsPage ++;
                    this.News = res.data.data;
                    // this.grtpicNews();
                    setTimeout(() => this.mostNews_skeleton = false, 500);
                    //console.log(res.data);
                })
                .catch(() => {
                });
            },
            infograph_img () {
                this.$axios.get("articles/infograph_img")
                .then(data => {
                    this.main_img = data.data.image;
                    this.mob_img = data.data.mobile;
                })
            },
            grtpicNews () {
                this.axios_load = true;
                this.loader = 'loading';
                this.$axios.get("articles/GetInfograph/6/"+this.cat_id+"?page=" + this.NewsPage, {
                })
                .then(res => {
                    this.axios_load = false;
                    this.mostNews_skeleton = false
                    this.NewsPage ++;
                    if (this.NewsPage === 2) {
                        this.cards = res.data.data;
                    } else {
                        this.cards = this.cards.concat(res.data.data);
                    }
                    if (res.data.data.length < 9) {this.getMorenews = false;}
                    this.startAutoplay();
                })
            },
        },
        filters: {
            subStrArtTitle: function (string) {if (string != null) {return string.substring(0, 24) + '...';}},
            subStrlastNewsitle: function (string) {if (string != null) {return string.substring(0, 130) + '...';}},
        },
    }

    </script>
<style>
.full-height-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.full-height-img:hover {
    transform: scale(1.05);
}




.inf .v-carousel {
    overflow:visible
}
.inf .v-carousel__controls .v-icon {
    font-size: 8px;
}
.inf .v-carousel__controls {
    position: relative;
    background: transparent;
}
.inf .mdi-circle::before {
    content: ""!important;
}
.inf .v-carousel__controls__item.v-btn.v-btn--icon {
    background-color: #000; 
    width: 10px;
    height: 10px;
}
  
.inf .v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active {
    background-color: #cac2c2; 
}

.inf .v-carousel__controls__item.v-btn.v-btn--icon:hover {
    background-color: black; 
    
}
</style>
<style scoped>

.item {
    display: block;
    height: 100%;
}
.hover-effect {
    position: relative;
    overflow: hidden;
}

.side-card {
    height: 300px;
    width: 300px;
    margin: 0 0 12px 0;
}

.center-card {
    height: 300px;
    width: 100%;
}

.image-content {
    border-radius: 4px;
    height: 100%;
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-icon {
    width: 42px;
    height: auto;
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-effect:hover .hover-icon {
    opacity: 1;
}

.hover-effect:hover .overlay {
    opacity: 1;
}

.cu-ti {
    padding: 12px;
    text-align: center;
}

.cu-h-info {
    height: 612px;
}

.mid-cu {
    font-size: 24px;
    text-align: center;
    margin-top: 8px;
}

.button-section {
    margin-top: 20px;
}

.custom-button {
    background: transparent !important;
    border: 1px solid #ccc; 
    color: black; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    font-weight: bold;
    padding: 20px 16px !important;
    width: 180px;
}

.button-icon {
    width: 20px; 
    height: auto; 
    margin-left: 5px;
}

@media (min-width: 1024px) and (max-width: 1263px) {
  

  .side-card {
        height: 450px;
        width: 100%;
        margin-bottom: 16px;
    }
  
  }

  .card {
    margin-bottom: 20px;
}

.swiper-container {
    width: 100%;
    height: auto;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.slide-image:hover {
    transform: scale(1.05); 

}

@media (max-width: 600px) {
    .swiper-slide {
        height: auto;
    }
}



@media (min-width: 320px) and (max-width: 4480px) {
  
    .swiper-container {
    min-height: 530px;
}
  
}



.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}


.breadcrumb-container {
  background-image: url('https://alkafeel.net/main_bg/uploads/70c90793aed1380959040fa6296f5c39.jpg'); 
  background-size: cover;
  background-position: center;
  height: 250px;
}
.v-breadcrumbs {
  margin: 0; 
}

.more_time {
    color: #979797;
    font-size: 13px;
    text-align: center;
}

.w-100 {
    width: 100%;
}

.scroll-horizontal {
    width: 100%; 
    padding-bottom: 8px;
    overflow-x: scroll; 
    overflow-y: hidden; 
    white-space: nowrap; 
    scrollbar-width: thin; 
    scrollbar-color: #a0a0a0 #ecf0f1;
}

/* .scroll-horizontal::-webkit-scrollbar {
    display: ;
} */

.scroll-horizontal::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 8px; 
}

.scroll-horizontal::-webkit-scrollbar-track {
    background: #ecf0f1; 
}

</style>